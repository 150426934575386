<template>
    <div class="plan_wrapper">
        <div class="header">
            <div class="logo">
                <img src="@/assets/images/logo2.svg" alt="Logo">
            </div>
            <div>
                <router-link class="logout" :to="{ name: 'Login' }"><i class="fa fa-sign-out-alt"></i>&nbsp; Sign out</router-link>
            </div>
        </div>
        <div class="plan_container">
            <div class="subscription_wpr">
                <div class="section_title">
                    <h3>Simple Pricing</h3>
                    <h6>Choose a plan that fits your coaching needs and start your 30-day trial.</h6>
                </div>
                <div class="upgrade_area">
                    <div class="plan_type">
                        <label for="plan" class="plan_switch">
                            <input type="checkbox" id="plan" true-value="year" false-value="month" v-model="planType" hidden>
                            <div class="switch"></div>
                            <span :class="{'active' : planType == 'month'}">Monthly</span>
                            <span :class="{'active' : planType == 'year'}">Yearly</span>
                        </label>
                        <div class="info">
                            2 months free
                            <img src="@/assets/images/curve_arrow.svg" alt="arrow">
                        </div>
                        <!-- Monthly
                        <label for="plan" class="switch_option capsule_btn">
                            <input type="checkbox" id="plan" true-value="year" false-value="month" v-model="planType" hidden>
                            <div><span></span></div>
                        </label>
                        Yearly -->
                    </div>
                </div>
                <div class="contact_loader" v-if="planLoader">
                    <quote-loader />
                </div>
                <div class="subscription-plans">
                    <div class="subscription" :class="{ active: activePlan == 'solo' }" @click="activePlan = 'solo'" v-if="plans[planType]">
                        <div class="header">
                            <h3><span class="uppercase">{{ plans[planType].solo.nickname }}</span> <span class="discount" v-if="plans[planType].solo.interval == 'year'">15% <small>Save</small></span></h3>
                            <div class="price"><span class="sup">$</span>{{ plans[planType].solo.amount }}<span class="sub">/{{ plans[planType].solo.interval }}</span></div>
                        </div>
                        <div class="features">
                            <ul>
                                <!-- <li v-for="(feature, f) of plans[planType].solo.features" :key="f"><i class="fas fa-check"></i>{{ feature }}</li> -->
                                <li><i class="fas fa-check"></i>500 contacts</li>
                                <li><i class="fas fa-check"></i>Unlimited email sends</li>
                                <li><i class="fas fa-check"></i>Client portal with chat</li>
                                <li><i class="fas fa-check"></i>Limited coaching features</li>
                                <li><i class="fas fa-check"></i>Limited sales features</li>
                                <li><i class="fas fa-check"></i>Leaderboards</li>
                                <li><i class="fas fa-check"></i>Thrive academy</li>
                            </ul>
                        </div>
                        <div class="footer">
                            <button type="button" :disabled="subscriptionLoader" @click="startTrial(plans[planType].solo)" class="btn"><i class="fa fa-spin fa-spinner" v-if="subscriptionLoader && activePlan == 'solo'"></i>&nbsp; {{ subscriptionLoader && activePlan == 'solo' ? 'Processing' : 'Start 30-day free trial' }}</button>
                        </div>
                    </div>
                    <div class="subscription growth" :class="{ active: activePlan == 'growth' }" @click="activePlan = 'growth'" v-if="plans[planType]">
                        <div class="header">
                            <h3><span class="uppercase">{{ plans[planType].growth.nickname }}</span> <span class="discount" v-if="plans[planType].growth.interval == 'year'">15% <small>Save</small></span></h3>
                            <div class="price"><span class="sup">$</span>{{ plans[planType].growth.amount }}<span class="sub">/{{ plans[planType].growth.interval }}</span></div>
                        </div>
                        <div class="features">
                            <ul>
                                <!-- <li v-for="(feature, f) of plans[planType].growth.features" :key="f"><i class="fas fa-check"></i>{{ feature }}</li> -->
                                <li><i class="fas fa-check"></i>2,000 contacts</li>
                                <li><i class="fas fa-check"></i>Unlimited email sends</li>
                                <li><i class="fas fa-check"></i>Client portal with chat</li>
                                <li><i class="fas fa-check"></i>Limited coaching features</li>
                                <li><i class="fas fa-check"></i>Limited sales features</li>
                                <li><i class="fas fa-check"></i>Leaderboards</li>
                                <li><i class="fas fa-check"></i>Thrive academy</li>
                            </ul>
                        </div>
                        <div class="footer">
                            <button type="button" :disabled="subscriptionLoader" @click="startTrial(plans[planType].growth)" class="btn"><i class="fa fa-spin fa-spinner" v-if="subscriptionLoader && activePlan == 'growth'"></i>&nbsp; {{ subscriptionLoader && activePlan == 'growth' ? 'Processing' : 'Start 30-day free trial' }}</button>
                        </div>
                    </div>
                    <div class="subscription pro" :class="{ active: activePlan == 'pro' }" @click="activePlan = 'pro'" v-if="plans[planType]">
                        <div class="header">
                            <h3><span class="uppercase">{{ plans[planType].pro.nickname }}</span> <span class="discount" v-if="plans[planType].pro.interval == 'year'">15% <small>Save</small></span></h3>
                            <div class="price"><span class="sup">$</span>{{ plans[planType].pro.amount }}<span class="sub">/{{ plans[planType].pro.interval }}</span></div>
                        </div>
                        <div class="features">
                            <ul>
                                <!-- <li v-for="(feature, f) of plans[planType].pro.features" :key="f"><i class="fas fa-check"></i>{{ feature }}</li> -->
                                <li><i class="fas fa-check"></i>12,000 contacts</li>
                                <li><i class="fas fa-check"></i>Unlimited email sends</li>
                                <li><i class="fas fa-check"></i>Client portal with chat</li>
                                <li><i class="fas fa-check"></i><span>Unlimited</span>coaching features</li>
                                <li><i class="fas fa-check"></i><span>Unlimited</span>sales features</li>
                                <li><i class="fas fa-check"></i>Leaderboards</li>
                                <li><i class="fas fa-check"></i>Thrive academy</li>
                                <li><i class="fas fa-check"></i><label>Growth add-ons</label></li>
                            </ul>
                        </div>
                        <div class="footer">
                            <button type="button" :disabled="subscriptionLoader" @click="startTrial(plans[planType].pro)" class="btn"><i class="fa fa-spin fa-spinner" v-if="subscriptionLoader && activePlan == 'pro'"></i>&nbsp; {{ subscriptionLoader && activePlan == 'pro' ? 'Processing' : 'Start 30-day free trial' }}</button>
                        </div>
                    </div>
                    <span class="badge">Most Popular Plan</span>
                </div>
            </div>
            <div class="comparison_area">
                <div class="section_title mb-2 mt-5">
                    <h3>Compare All Features</h3>
                </div>
                <div class="area_wpr">
                    <div class="area_label">Coaching Tools</div>
                    <div class="area_table">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><label>Solo</label></th>
                                    <th><label class="growth">Growth</label></th>
                                    <th><label class="pro">Pro</label></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Client Portal
                                        </div>
                                    </td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Rewards
                                        </div>
                                    </td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Great question! Playbooks are mobile responsive mini-courses you can use to onboard your new clients. Think about playbooks like interactive checklists or execution plans your prospects, clients, and staff can use to complete a task or learn a concept. They are a fantastic addition to your onboarding process.</div>
                                            </div>
                                            Playbooks
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Content Pages
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Our form builder allows you to create beautiful multi-step intake or information gathering forms, progress tracking, picture, and document uploading, collect signatures and initials and so much more. The use cases are endless and this is one of our most heavily used features in the Thrive Coach suite.</div>
                                            </div>
                                            Forms
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Habit Tracking
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Progress Tracking
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Workouts
                                        </div>
                                    </td>
                                    <td>50</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Workout Plans
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Thrive Community (Fall)
                                        </div>
                                    </td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <span class="badge">Coaches Choice</span>
                    </div>
                    <div class="area_label">Sales & Marketing Tools</div>
                    <div class="area_table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Broadcasts are email campaigns that you can send to thousands of contacts at one time to accelerate relationships. They come complete with open rate analytics and resending options. Most of our users choose this option for weekly newsletters and promotions. Broadcasts are a great way to generate sales and new interest in your programs.</div>
                                            </div>
                                            Broadcasts
                                        </div>
                                    </td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Sequences are SMS and Email campaigns that send at predetermined and dripped intervals. These are great for nurturing new leads over a period of time or onboarding new clients into your evergreen programs.</div>
                                            </div>
                                            Sequences
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Templates are canned email or SMS messages that you can fire at will from your launchpad, bulk actions, or as part of a customer onboarding journey. Our users love them and they have saved hours in repetitive messaging.</div>
                                            </div>
                                            Templates
                                        </div>
                                    </td>
                                    <td>10</td>
                                    <td>25</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Perhaps the coolest tool we offer. A customer journey can take any prospect, client, or even a team member through a series of pre-determined events based on the contact’s behavior. These are unbeatable for running challenges and training staff as well as nurturing a lead to invest in your services.</div>
                                            </div>
                                            Journeys
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Opt-in Pages
                                        </div>
                                    </td>
                                    <td>5</td>
                                    <td>10</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Checkout pages are landing pages that collect payment information and then deliver your product. Thrive Checkout™ is our signature checkout page software and allows you to create beautiful mobile responsive order forms for your programs. They come complete with timers, social proof, guarantees, product info, and a myriad of pricing options like one-time fee, recurring subscriptions, paid and free trials, split pays, name your price, and you can even offer free products to get subscribers!</div>
                                            </div>
                                            Checkout Pages
                                        </div>
                                    </td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Products are programs, services, and merchandise you sell. You are able to create unlimited products and use a myriad of pricing scenarios like one-time fee, recurring subscriptions, paid and free trials, split pays, name your price, and even free products to get a subscriber!</div>
                                            </div>
                                            Products
                                        </div>
                                    </td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                    <td>Unlimited</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Transaction Fees
                                        </div>
                                    </td>
                                    <td>5% Transaction fee</td>
                                    <td>2% Transaction fee</td>
                                    <td>No Fees</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Thrive Store (June)
                                        </div>
                                    </td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <span class="badge">Coaches Choice</span>
                    </div>
                    <div class="area_label">Admin Tools</div>
                    <div class="area_table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Tags
                                        </div>
                                    </td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Groups
                                        </div>
                                    </td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Files
                                        </div>
                                    </td>
                                    <td>500 MB</td>
                                    <td>500 MB</td>
                                    <td>500 MB</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Notifications
                                        </div>
                                    </td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">All pro accounts have the ability to make all client-facing emails, forms, pages, playbooks, waivers, agreements, and quizzes are powered by their company. No Thrive Coach branding will be shown on your assets only your company name and links to your website. Our users love this because it appears to be a custom-made tool for their client’s value and not a paid service.</div>
                                            </div>
                                            White Label
                                        </div>
                                    </td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                            </tbody>
                        </table>
                        <span class="badge">Coaches Choice</span>
                    </div>
                    <div class="area_label">Growth Add-Ons</div>
                    <div class="area_table">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Thrive coach uses its sister software Thrive Funnels™ to create beautiful mobile responsive opt-in and sales funnels that get leads into your pipelines. We have a library of more than 300 funnels and growing. Plus we give away a new funnel each month to help your keep things moving in the right direction with your business. As an Thrive Coach Growth or Pro member, you can add unlimited funnels to your account for just $39 per month!</div>
                                            </div>
                                            Unlimited Funnels
                                        </div>
                                    </td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td>$39 / Month</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">It’s super easy! We offer many options for uploading or syncing contacts between platforms. You can sync your contacts with Mindbody or Zen Planner via our in-app integration, enter a contact manually, use an opt-in form, or upload any contact list using a CSV file. If you are Pro member our VA can do this for you!</div>
                                            </div>
                                            Extra Contacts
                                        </div>
                                    </td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">You get a full 30-day step-by-step implementation program, a personal onboarding call, and 30-days unlimited use of the Thrive Coach software suite!</div>
                                            </div>
                                            Extra Coaches
                                        </div>
                                    </td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td>$2 / Coach / Month</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Permissions
                                        </div>
                                    </td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="fas fa-check-circle"></i></td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Some business owners have multiple physical locations. We allow Pro users to add unlimited additional sub-account for just $10 per month per additional location. This is great for franchise owners with multiple locations or online coaches with multiple brands.</div>
                                            </div>
                                            Extra Locations
                                        </div>
                                    </td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td><i class="far fa-times-circle"></i></td>
                                    <td>$20 / Location / Month</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="title">
                                            <div class="tooltip">
                                                <i class="fas fa-info"></i>
                                                <div class="quick_info">Donec a egestas purus. Integer nulla neque, porta ac mi eget, finibus commodo dolor.</div>
                                            </div>
                                            Extra Storage
                                        </div>
                                    </td>
                                    <td>$2 / GB</td>
                                    <td>$2 / GB</td>
                                    <td>$2 / GB</td>
                                </tr>
                            </tbody>
                        </table>
                        <span class="badge">Coaches Choice</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Select Plan',

        data () {
            return {
                form: {
                    business_name: '',
                    mobile_number: '',
                },
                planType: 'month',
                activePlan: 'pro',
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                subscriptionLoader: false,
                planLoader: false,
            };
        },

        watch: {
            plans (plans) {
                const vm = this;

                if (vm.$route.query.plan) {
                    Object.values(plans[vm.planType]).forEach((plan) => {
                        if (plan.plan_code == vm.$route.query.plan) {
                            vm.activePlan = plan.nickname;
                        }
                    });
                }
            },
        },

        computed: mapState({
            acountDetails: state => state.registerModule.acountDetails,
            plans: state => state.registerModule.plans,
            user: state => state.authModule.user,
        }),

        mounted () {
            const vm = this;

            vm.planLoader = true;
            vm.getSubscriptionPlans().then((result) => {
                vm.planLoader = false;
            });

            vm.getAccountDetail(vm.$route.params.token).then((result) => {
                if (!result) {
                    const options = Helper.swalWarningOptions('Oops!', 'The account token is invalid or expired');

                    Swal.fire(options).then((ev) => {
                        if (ev.isConfirmed) {
                            vm.$router.push({ name: 'Register' });
                        }
                    });
                }
            });

            if (vm.$route.query.plan) {
                vm.activePlan = vm.$route.query.plan;
            }
        },

        methods: {
            ...mapActions({
                getAccountDetail: 'registerModule/getAccountDetail',
                getSubscriptionPlans: 'registerModule/getSubscriptionPlans',
                createSubscription: 'registerModule/createSubscription',
            }),

            toggleCoachingFeatures (e) {
                let el = e.currentTarget.nextSibling;

                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    el.classList.add('active');
                }
            },

            startTrial (plan) {
                const vm = this;
                const params = {
                    price_id: plan.price_id,
                    token: vm.$route.params.token,
                };

                vm.subscriptionLoader = true;

                axios.post(`${vm.billingApi}/stripe/checkout-session`, params, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.subscriptionLoader = false;
                    window.location.href = resp.data.url;
                }).catch((err) => {
                    vm.subscriptionLoader = false;
                    Toastr.error(err.response.data.error);
                });
            },
        },
    }
</script>

<style scoped>
    @font-face {
        font-family: "Honeyscript";
        src: url('~@/assets/font/honey-script.light.ttf') format("truetype");
    }

    .plan_wrapper {
        width: 100%;
    }

    .plan_wrapper .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .plan_wrapper .header .logo {
        margin: 0 0 40px;
    }

    .plan_wrapper .header .logo img {
        max-width: 200px;
        height: auto;
        width: 100%;
    }

    .plan_wrapper .header .logout {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 10px 20px;
        background: #eee;
        border-radius: 17px;
        cursor: default;
        display: inline-block;
        text-decoration: none;
    }

    .plan_wrapper .area_content {
        max-width: 500px;
        width: 100%;
        text-align: left;
    }

    .plan_wrapper h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 600;
        color: #121525;
        margin-bottom: 10px;
    }

    .plan_wrapper h5 {
        font-size: 13px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 500;
        max-width: 350px;
    }

    .plan_container {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: center;
        justify-content: center;
    }

    .subscription_wpr {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0 0;
    }

    .subscription_wpr .result_wpr table td p {
        font-weight: 400;
    }

    .subscription_wpr .result_wpr table td:first-child,
    .subscription_wpr .result_wpr table th:first-child {
        width: auto;
        text-align: left;
        padding: 20px 15px;
    }

    .upgrade_area {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 35px;
    }

    .upgrade_area .price {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 10px;
    }

    .upgrade_area .price span.sub {
        font-size: 13px;
        line-height: 16px;
        align-self: flex-end;
        padding: 0 0 3px 3px;
    }

    .upgrade_area h3 {
        font-size: 20px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
    }

    .plan_type {
        display: flex;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        color: #121525;
        position: relative;
    }
    .plan_type .plan_switch {
        width: 180px;
        height: 45px;
        border: 1px solid #9fb3ca;
        background: #fff;
        border-radius: 25px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
    }
    .plan_type .plan_switch span{
        flex: 1;
        text-align: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        transition: all 0.3s ease-in-out;
    }
    .plan_type .plan_switch span.active{
        color: #fff;
    }
    .plan_type .plan_switch .switch{
        position: absolute;
        width: calc(50% - 4px);
        top: 4px;
        left: 4px;
        bottom: 4px;
        background: #2F7EED;
        border-radius: 20px;
        transition: all 0.3s ease-in-out;
    }
    .plan_type .plan_switch input[type=checkbox]:checked ~ .switch{
        left: 50%;
    }

    .plan_type .info{
        position:absolute;
        left: 130%;
        bottom: 110%;
        font-family: "Honeyscript";
        font-size: 30px;
        line-height: 40px;
        color: #121525;
        white-space: nowrap;
    }
    .plan_type .info img{
        position: absolute;
        top: 75%;
        right: 75%;
        width: 65px;
        transform: rotate(-75deg);
    }

    .subscription {
        background: #fff;
        box-shadow: 1px 4px 7px rgba(0, 0, 0, 0.08);
        position: relative;
        padding-bottom: 50px;
        height: 100%;
        width: 33.333%;
    }

    .subscription .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 25px 15px;
        border-bottom: 1px solid #eee;
        background: #EAF3FF;
        position: relative;
    }
    .subscription.growth .header{
        background: #FFF8E4;
    }
    .subscription.pro .header{
        background: #FFEDE7;
    }

    .subscription .header h3 {
        font-size: 20px;
        line-height: 27px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
    }
    .subscription .header h3 span{
        padding: 6px 15px;
        font-size: 11px;
        line-height: 14px;
        color: #fff;
        border-radius: 20px;
        background: #2F7EED;
        margin-bottom: 5px;
    }
    .subscription.growth .header h3 span{
        background: #FFBF25;
    }
    .subscription.pro .header h3 span{
        background: #FF8D67;
    }

    .subscription .header .price, .addOn_card .price {
        font-size: 36px;
        line-height: 46px;
        font-weight: 400;
        color: #121525;
        display: flex;
    }

    .subscription .header .price span.sub, .addOn_card .price span.sub {
        font-size: 13px;
        line-height: 28px;
        font-weight: 500;
        align-self: flex-end;
        padding: 0 0 3px 3px;
    }

    .subscription .footer {
        display: flex;
        justify-content: center;
        padding: 0 15px;
        /* position: absolute;
        bottom: 0; */
        width: 100%;
    }

    .subscription .features {
        padding: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .subscription .features ul{
        max-width: 230px;
        width: 100%;
    }
    .subscription .features ul li {
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 6px 0;
        display: flex;
        text-align: left;
    }
    .subscription .features ul li span{
        color: #FF8D67;
        font-weight: 500;
        padding-right: 5px;
    }
    .subscription .features ul li label{
        padding-bottom: 4px;
        background-image: url('~@/assets/images/object1.png');
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: contain;
    }

    .subscription .features ul li i {
        margin-right: 10px;
        margin-top: 2px;
        font-size: 9px;
        color: #121525;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        /* background: rgba(117, 210, 24, 0.7); */
    }

    .subscription .features h4 {
        font-size: 14px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin: 15px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .subscription .features h4 i {
        font-size: 13px;
        margin-left: 10px;
    }

    .subscription .features .more_ft {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
    }

    .subscription .features .more_ft ul {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    .subscription .features .more_ft ul.active {
        max-height: 600px;
    }

    .subscription .features a {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 10px 20px;
        background: #eee;
        border-radius: 17px;
        cursor: default;
        display: inline-block;
    }
    .subscription .header h3 span.discount {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        background: transparent;
        border-radius: 0;
        color: #121525;
        padding: 7px 10px;
        margin-left: 10px;
        position: absolute;
        width: 40px;
        height: 40px;
        right: 0;
        top: 0;
        z-index: 1;
    }
    .subscription .header h3 span.discount:before{
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        border-top: 35px solid #accfff;
        border-right: 35px solid #accfff;
        border-bottom: 35px solid transparent;
        border-left: 35px solid transparent;
        z-index: -1;
    }
    .subscription.growth .header h3 span.discount:before{
        border-top: 35px solid #ffd97c;
        border-right: 35px solid #ffd97c;
    }
    .subscription.pro .header h3 span.discount:before{
        border-top: 35px solid #ffc7b4;
        border-right: 35px solid #ffc7b4;
    }

    .subscription-plans {
        padding: 0px 15px 30px 15px;
        display: flex;
        justify-content: center;
        position: relative;
        max-width: 1110px;
        width: 100%;
    }
    .subscription-plans .badge{
        padding: 5px 15px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: #121525;
        background: #F9C600;
        border-radius: 10px 10px 0 0;
        position: absolute;
        white-space: nowrap;
        left: calc(100% - 84px);
        top: 40%;
        transform: translateY(-50%) rotate(90deg);
    }

    button.btn {
        max-width: 220px;
        width: 100%;
        background: #000;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #fff;
        font-weight: 300;
        padding: 12px 15px;
        border-radius: 5px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;
        margin-top: 5px;
    }

    .subscription.active {
        border: 1px solid #d9d9d9;
    }

    .subscription.active .footer button.btn {
        background: #000;
        color: #FFF;
    }

    :deep(.contact_loader, .listing_loader) {
        background: transparent;
    }

    .section_title{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;
    }
    .section_title h3{
        font-size: 40px;
        line-height: 50px;
        font-weight: 500;
        color: #121525;
        position: relative;
        padding-bottom: 22px;
        z-index: 1;
    }
    .section_title h3::after{
        position: absolute;
        content: '';
        right: -5%;
        bottom: 20px;
        width: 60%;
        height: 20px;
        background-image: url('~@/assets/images/object1.png');
        background-repeat: no-repeat;
        background-position: 100% 100%;
        background-size: contain;
        z-index: -1;
    }
    .section_title h6{
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        color: #121525;
        max-width: 650px;
    }
    .comparison_area{
        max-width: 1110px;
        padding: 0 15px;
        width: 100%;
    }
    .comparison_area .area_wpr{
        background: #fff;
        padding: 70px 50px 5px 50px;
        border: 0;
    }
    .comparison_area .area_wpr .area_label{
        padding: 15px 30px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        color: #121525;
        background: #F3F5F8;
        border-bottom: 1px solid #d9d9d9;
        text-align: left;
    }
    .comparison_area .area_table {
        position: relative;
        margin-bottom: 70px;
    }
    .comparison_area .area_table .badge{
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #fff;
        background: #121525;
        border-radius: 8px 8px 0 0;
        position: absolute;
        white-space: nowrap;
        left: calc(100% - 48px);
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
    }
    .comparison_area .area_table table{
        width: 100%;
        border-collapse: collapse;
    }
    .comparison_area table th{
        padding: 20px 1px 0 1px;
    }
    .comparison_area table th:not(:first-child){
        width: 22%;
    }
    .comparison_area table th label{
        background: #2f7eed;
        display: block;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #fff;
        padding: 8px 15px;
        border-radius: 3px;
    }
    .comparison_area table thead th label.pro{
        background: #FF8D67;
    }
    .comparison_area table thead th label.growth{
        background: #FFBF25;
    }
    .comparison_area table tbody{
        position: relative;
        z-index: 1;
    }
    .comparison_area table tbody:after{
        position: absolute;
        content: '';
        width: 22%;
        top: 0;
        right: 0;
        bottom: 0;
        background: #ffede7;
        box-shadow: 0px 0px 35px rgba(174, 174, 174, 0.18);
        z-index: -1;
    }
    .comparison_area table tbody tr{
        border-bottom: 1px solid #e9e9e9;
    }
    .comparison_area table td{
        padding: 18px;
        font-size: 13px;
        line-height: 18px;
    }
    .comparison_area table td i{
        font-size: 16px;
        color: #2f7eed;
    }
    .comparison_area table td i.far{
        color: #95a4c5;
    }
    .comparison_area table td:first-child{
        text-align: left;
        padding-left: 0;
        font-weight: 500;
    }
    .comparison_area table td:not(:first-child){
        width: 22%;
    }
    .comparison_area table td .title{
        display: flex;
        gap: 10px;
    }
    .comparison_area table td .tooltip{
        width: 15px;
        height: 15px;
        flex-shrink: 0;
        border: 1px solid #999;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
        position: relative;
    }
    .comparison_area table td .tooltip i{
        font-size: 8px;
        color: #999;
    }
    .quick_info{
        border-radius: 5px;
        border: 1px solid #eaeaea;
        box-shadow: 0 0 15px rgb(0 0 0 / 10%);
        background: #fff;
        width: 320px;
        position: absolute;
        left: -15px;
        top: auto;
        bottom: 100%;
        display: none;
        margin-bottom: 10px;
        font-size: 10px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 7px 10px;
    }
    .quick_info:after {
        content: "";
        position: absolute;
        bottom: -6px;
        top: auto;
        left: 15px;
        width: 10px;
        height: 10px;
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 0;
        background: #fff;
        transform: rotate(45deg);
        z-index: 2;
    }
    .tooltip:hover .quick_info, .week_list li .week_panel ul li .box_item ol > li > label:hover .quick_info{
        display: block;
        animation: smoothMove 0.5s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    @keyframes smoothMove {
        0% {
            bottom: 30%;
            opacity: 0;
        }

        10% {
            bottom: 30%;
            opacity: 0.5;
        }

        100% {
            bottom: 100%;
            opacity: 1;
        }
    }
    @media(max-width: 991px){
        .plan_wrapper{
            margin-top: -80px;
        }
        .subscription .features ul li{
            font-size: 12px;
            line-height: 19px;
        }
    }
    @media(max-width: 767px){
        .subscription-plans{
            max-width: 360px;
            flex-direction: column;
            padding: 0;
        }
        .subscription{
            width: 100%;
            margin: 0 auto;
            transition: all 0.3s ease-in-out;
        }
        .subscription:not(.active){
            width: 90%;
            transition: all 0.3s ease-in-out;
        }
        .subscription .header .price{
            font-size: 35px;
        }
        .section_title h3{
            font-size: 30px;
            line-height: 40px;
        }
        .section_title h6 {
            font-size: 15px;
            line-height: 22px;
            max-width: 400px;
        }

        .comparison_area{
            padding: 0;
        }
        .comparison_area .area_wpr{
            padding: 40px 20px 5px 20px;
        }
        .comparison_area .area_wpr .area_label{
            font-size: 15px;
            line-height: 22px;
            padding: 10px 20px;
        }
        .comparison_area .area_table{
            margin-bottom: 40px;
        }
        .comparison_area table tr{
            display: flex;
            flex-wrap: wrap;
        }
        .comparison_area table td:first-child{
            width: 100%;
            padding: 10px 0;
        }
        .comparison_area table th:first-child{
            display: none;
        }
        .comparison_area table th:not(:first-child){
            width: 33.333%;
        }
        .comparison_area table td:not(:first-child) {
            width: 33.333%;
            padding: 15px 10px;
            font-size: 11px;
            line-height: 16px;
        }
        .comparison_area table tbody:after {
            width: 33.333%;
        }
    }
    @media(max-width: 499px){
        .plan_type .info{
            left: 100%;
            font-size: 26px;
            line-height: 34px;
        }
        .plan_type .info img {
            position: absolute;
            top: 100%;
            right: 35%;
            width: 45px;
            transform: rotate(-85deg);
        }
        .subscription-plans .badge{
            display: none;
        }
    }
</style>
